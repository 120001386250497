import React from "react"
import Seo from "../components/Seo"
import emoji from "../images/404.svg";
import "../styles/style.scss"

const NotFoundPage = () => (
  <main>
    <Seo title="404: Not found"/>
    <div className="not-found">
      <div className="emoji">
        <img src={emoji} alt="sad"/>
      </div>
      <div className="text"><h1>404 - PAGE NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        <a className="button is-primary is-outlined is-rounded" href="/">
          <span>Homepage</span>
        </a>
      </div>
    </div>
  </main>
)

export default NotFoundPage;
